import React from 'react';
import { Link } from 'react-router-dom'; // must import Router, else switch doesn't work
import Velocity from 'velocity-animate';
import Nav from '../Nav/Nav';
import bgImage from '../img/fermgroup2.png';
const bgUrl = `${ bgImage }`

class FrontPageJera extends React.Component {

  constructor( props ) {
    super( props );
    this.state = {
      tmp:false,
    };
    //this.preventSubmit = this.preventSubmit.bind(this);
  }

  componentDidMount(){
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div id="frontpage" className="front-wrapper">
        <img style={{ position:'absolute', top:0, left:0, width:'100%', height:'100%', objectFit:'cover', opacity:1 }} alt="" src={ bgUrl } />
        <div className="front-shadow" />
        <div draggable="false" className="front-nav" >
          <div draggable="false" className="front">
            Jera Collection
          </div>
        {/*
          <Link draggable="false" to="/open-account/">
            <div draggable="false" className="open-account-button front-open-account">Open Account</div>
          </Link>
        */}
        </div>
        {/*
        <Nav/>
        */}
      </div>
    )
  }
}

export default FrontPageJera
