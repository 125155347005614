import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; // must import Router, else switch doesn't work
import FrontPage from '../FrontPage/FrontPage';
import FrontPageJera from '../FrontPage/FrontPageJera';
//import Store from '../Store/Store';
import BlogsList from '../Blogs/BlogsList';
import Velocity from 'velocity-animate';
//import '../css/index.css';
import bgImage from '../img/fermgroup2.png';

import bg1 from '../img/Jera_Sealed_Fermentation.jpg'
import bg2 from '../img/Jera_Open_fermentation.jpg'
import bg3 from '../img/Bottles_Jera.jpg'
import bg4 from '../img/Wyn_Shotglasses.jpg'
import bg5 from '../img/Ashtrays_TomDixon.jpg'
import bg6 from '../img/MM.jpg'

import birtaTable from '../img/birta_table.png'
import birtaCeiling from '../img/birta_ceiling.png'
import birtaWall from '../img/birta_wall.png'

import qr1 from '../img/birta_1.png'
import qr2 from '../img/birta_2.png'
import qr3 from '../img/birta_3.png'

const bgUrl = `${ bgImage }`
const bgUrl1 = `${ bg1 }`
const bgUrl2 = `${ bg2 }`
const bgUrl3 = `${ bg3 }`
const bgUrl4 = `${ bg4 }`
const bgUrl5 = `${ bg5 }`
const bgUrl6 = `${ bg6 }`

const birtaTableUrl1 = `${ birtaTable }`
const birtaCeilingUrl1 = `${ birtaCeiling }`
const birtaWallUrl1 = `${ birtaWall }`

const qrUrl1 = `${ qr1 }`
const qrUrl2 = `${ qr2 }`
const qrUrl3 = `${ qr3 }`



const scrollToTop = ( duration = 1000 ) => {
    Velocity( document.documentElement, "scroll", { duration:duration, easing:"easeOutQuad" } )
}

const ScrollToTopIcon = ( props ) => (
  <div style={{ textAlign:'center', userSelect:'none', fontSize:'14px', fontWeight:'bold', color:'black' }} >
    <div onClick={ () => { scrollToTop( props.duration ) } } style={{ cursor:'pointer', display:'inline-block', textAlign:'center', padding:'15px 20px', backgroundColor:props.backgroundColor ? props.backgroundColor : '#eee', borderRadius:'50%' }} >
      <i style={{ fontSize:'36px', padding:'0', cursor:'pointer', transform:'translateY(6px)' }} className="material-icons">keyboard_arrow_up</i>
    </div>
  </div>
)

const BirtaCollection = () => (
  <Fragment>
  <div id="birta-collection" className="info-wrapper" style={{ background:'white', padding:'30px 0 0 0' }} >

    <div className="products" style={{ padding:'0' }} >
      <h1 style={{ paddingLeft:'30px', boxSizing:'border-box' }}>Birta Collection</h1>
      <div className="info" style={{ padding:'0 30px' }} >
        <p>Birta is an extended reality collection, mimicking stoneware, and porcelain. By using different clay bodies, Mette aims to bring an aspect of nature by playing with rough and smooth textures, opacity, and shape that abstractly imitate stone and water, creating modest centrepieces for the home. </p>
      </div>
      <div className="info" style={{ padding:'0 30px 0 30px', boxSizing:'border-box' }}>
        <p>Mette is dedicated to engineering a reality that integrates the artistic and the material with the virtual and scientific - utilizing 3D modelling and AR digital projection techniques allow patrons, manufacturers and consumers alike to experience a true sense of “object presence” within their own home.</p>
      </div>
    </div>

    <div className="products" style={{ padding:'0'}} >
      <div className="info" style={{ padding:'0' }}>
        <h2 style={{ paddingLeft:'30px', boxSizing:'border-box' }}>Table Lamp</h2>
        <img style={{ width:'100%', height:'100%', objectFit:'cover', opacity:1 }} alt="" src={ birtaTableUrl1 } />
      </div>
      <div className="info" style={{ padding:'0 30px 0 30px', boxSizing:'border-box' }}>
        <h2 className="desktop-only">&nbsp;</h2>
          <p>Click or scan QR code to see model in 3D or AR.</p>
          <a href="https://mettemyllynen.com/birta/3" target="_blank">
            <img style={{ }} alt="" src={ qrUrl3 } />
          </a>
      </div>
    </div>

    <div className="products" style={{ padding:'0'}} >
      <div className="info" style={{ padding:'0' }}>
        <h2 style={{ paddingLeft:'30px', boxSizing:'border-box' }}>Ceiling Lamp</h2>
        <img style={{ width:'100%', height:'100%', objectFit:'cover', opacity:1 }} alt="" src={ birtaCeilingUrl1 } />
      </div>
      <div className="info" style={{ padding:'0 30px 0 30px', boxSizing:'border-box' }}>
        <h2 className="desktop-only">&nbsp;</h2>
          <p>Click or scan QR code to see model in 3D or AR.</p>
          <a href="https://mettemyllynen.com/birta/2" target="_blank">
            <img style={{ }} alt="" src={ qrUrl2 } />
          </a>
      </div>
    </div>

    <div className="products" style={{ padding:'0'}} >
      <div className="info" style={{ padding:'0' }}>
        <h2 style={{ paddingLeft:'30px', boxSizing:'border-box' }}>Wall Lamp</h2>
        <img style={{ width:'100%', height:'100%', objectFit:'cover', opacity:1 }} alt="" src={ birtaWallUrl1 } />
      </div>
      <div className="info" style={{ padding:'0 30px 0 30px', boxSizing:'border-box' }}>
        <h2 className="desktop-only">&nbsp;</h2>
          <p>Click or scan QR code to see model in 3D or AR.</p>
          <a href="https://mettemyllynen.com/birta/1" target="_blank">
            <img style={{ }} alt="" src={ qrUrl1 } />
          </a>
      </div>
    </div>


    <div style={{ padding:'10px 0 30px 0' }} >
      <ScrollToTopIcon duration="300" />
    </div>

  </div>
  </Fragment>
)


const AccountPlans = () => (
  <Fragment>
  <div id="account-plans" className="info-wrapper" style={{ background:'white', padding:'30px 0 0 0' }} >

    <div className="products" style={{ padding:'0' }} >
      <h1 style={{ paddingLeft:'30px', boxSizing:'border-box' }}>Jera Collection</h1>
      <div className="info" style={{ padding:'0 30px' }} >
        <p>Jera is the name of a Norse rune that symbolizes fertility and harvest.</p>
        <p>With an increasingly busy lifestyle, fermentation may offer a simple but effective solution to access the nutrients in natural produce and not only help us keep well but also boost our immune system and mental health. These crocks are for the urban farmer, to feel pride in harvesting something one has made with one’s own hands.</p>
      </div>
      <div className="info" style={{ padding:'0 30px 0 30px', boxSizing:'border-box' }}>
          <p>Jera Collection consists of an open crock, and a water-sealed fermentation crock, both with three different sizes and lastly, a bottle that has two different sizes. The final pieces will be decorated with oxides and various clays to render an earthy and welcoming look, in addition to their fundamental uniqueness.</p>
      </div>
    </div>

    <div className="products" style={{ padding:'0'}} >
      <div className="info" style={{ padding:'0' }}>
        <h2 style={{ paddingLeft:'30px', boxSizing:'border-box' }}>Water sealed fermentation crock</h2>
        <img style={{ width:'100%', height:'100%', objectFit:'cover', opacity:1 }} alt="" src={ bgUrl1 } />
      </div>
      <div className="info" style={{ padding:'0 30px 0 30px', boxSizing:'border-box' }}>
        <h2 className="desktop-only">&nbsp;</h2>
          <p>This crock is meant for anaerobic fermentation like sauerkraut and has an airlock that can be filled with water to allow gasses to escape without oxygen entering and therefore preventing contamination of the batch.</p>
          <p>It is possible to have a tap incorporated with the Family and Couple sized crocks.</p>
          <p><b>Family 5L</b> - Base: 21.5 cm, Height: 26.5 cm <br/><b>Couples 2.5L</b> - Base: 16.5 cm, Height: 21 cm <br/><b>Single 1L</b> - Base: 12 cm, Height: 14 cm </p>
      </div>
    </div>

    <div className="products" style={{ padding:'0'}} >
      <div className="info" style={{ padding:'0' }}>
        <h2 style={{ paddingLeft:'30px', boxSizing:'border-box' }}>Open fermentation crock</h2>
        <img style={{ width:'100%', height:'100%', objectFit:'cover', opacity:1 }} alt="" src={ bgUrl2 } />
      </div>
      <div className="info" style={{ padding:'0 30px 0 30px', boxSizing:'border-box' }}>
        <h2 className="desktop-only">&nbsp;</h2>
          <p>This crock is meant for aerobic fermentation and has a lid that allows a muslin to be placed in-between to prevent insects from entering. </p>
          <p>It is possible to have a tap incorporated with the Family and Couple sized crocks.</p>
          <p><b>Family 5L</b> - Base: 21.5 cm, Height: 26.5 cm <br/><b>Couples 2.5L</b> - Base: 16.5 cm, Height: 21 cm <br/><b>Single 1L</b> - Base: 12 cm, Height: 14 cm </p>
      </div>
    </div>

    <div className="products" style={{ padding:'0'}} >
      <div className="info" style={{ padding:'0' }}>
        <h2 style={{ paddingLeft:'30px', boxSizing:'border-box' }}>Bottle</h2>
        <img style={{ width:'100%', height:'100%', objectFit:'cover', opacity:1 }} alt="" src={ bgUrl3 } />
      </div>
      <div className="info" style={{ padding:'0 30px 0 30px', boxSizing:'border-box' }}>
        <h2 className="desktop-only">&nbsp;</h2>
          <p>The bottles are ultimately meant for fermented vinegar that has to be kept away from sunlight but can also be used for kombucha or olive oil. </p>
          <p><b>Family 1L</b> - Base: 9 cm, Height: 18 cm <br/><b>Single 0.5L</b> - Base: 7 cm, Height: 25 cm </p>
      </div>
    </div>

    <div style={{ padding:'10px 0 30px 0' }} >
      <ScrollToTopIcon duration="300" />
    </div>

  </div>
  </Fragment>
)

const Projects = () => (
  <Fragment>
    <div id="projects" className="info-wrapper" style={{ background:'#e7e7e7', padding:'30px 0 0 0' }} >

      <div className="products" style={{ padding:'0' }} >
        <h1 style={{ paddingLeft:'30px', boxSizing:'border-box' }}>Projects</h1>
        <div className="info" style={{ padding:'0' }}>
          <h2 style={{ paddingLeft:'30px', boxSizing:'border-box' }}>Wyn Shotglasses</h2>
          <img style={{ width:'100%', height:'100%', objectFit:'cover', opacity:1 }} alt="" src={ bgUrl4 } />
        </div>
        <div className="info" style={{ paddingBottom:'20px' }} >
          <h2 className="desktop-only">&nbsp;</h2>
            <p>Wyn, the name of a Norse rune that symbolizes joy, companionship and victory after hardship. </p>
            <p>Russians have a whole culture linked to vodka that many in the west don’t associate with, and we often think of this particular liquor as cheap and unsophisticated.</p>
            <p>Therefore, Mette wanted to take the challenge and see how she could try to elevate it and make it more sophisticated with a set of a vodka glass and plate - similar to the cheese & wine concept.</p>
        </div>
      </div>

      <div className="products" style={{ padding:'0' }} >
        <div className="info" style={{ padding:'0' }}>
          <h2 style={{ paddingLeft:'30px', boxSizing:'border-box' }}>The drips</h2>
          <img style={{ width:'100%', height:'100%', objectFit:'cover', opacity:1 }} alt="" src={ bgUrl5 } />
        </div>
        <div className="info"  style={{ padding:'0 30px 0 30px', boxSizing:'border-box' }} >
          <h2 className="desktop-only">&nbsp;</h2>
          <p>While working at the Tom Dixon factory in 2018, Mette was assigned to design and make drips for The Coal Office restaurant. </p>
          <p>Her ambition was to make a functional, aesthetically pleasing range that would suit the space. </p>
        </div>
      </div>

      <div style={{ padding:'30px 0 40px 0' }} >
        <ScrollToTopIcon duration="400" backgroundColor="white" />
      </div>

    </div>
  </Fragment>
)


const About = () => (
  <Fragment>

    <div id="about" className="info-wrapper" style={{ background:'white', padding:'30px 0 30px 0' }} >

      <div className="products" style={{ padding:'0' }} >
        <h1 style={{ paddingLeft:'30px', boxSizing:'border-box' }}>About</h1>
        <div className="info" style={{ padding:'0' }} >
          <h2 style={{ paddingLeft:'30px', boxSizing:'border-box' }}>Mette Myllynen</h2>
          <img style={{ width:'100%', height:'100%', objectFit:'cover', opacity:1 }} alt="" src={ bgUrl6 } />
        </div>
        <div className="info" style={{ padding:'0 30px 0 30px', boxSizing:'border-box' }} >
          <h2 className="desktop-only">&nbsp;</h2>
          <p>Mette Myllynen is a unique Finno-Swedish ceramicist, artist and engineer who integrates physical art with our postmodern virtual world.</p>
          <p>Using cutting-edge technology, classical design techniques, 3D modelling and Augmented Reality platforms, she creates instalments and displays that are unlike any others - with a fine-tuned sense of organic and integrative styling, influenced by minimalistic Scandinavian design.</p>
          <p>Mette believes in providing new sustainable solutions with technology, and therefore her pieces are made in augmented reality to show how we could see or explore objects at our homes before we buy them – or possibly even before they are made. </p>
          <p>Because of her international upbringing she has a fascination towards diversity of cultures, the commonalities across them and their preservation. Mette aims her designs to be accessible globally and to promote a sustainable way of life through innovative designs and solutions.</p>
        </div>
      </div>

      <div style={{ padding:'30px 0 0 0' }} >
        <ScrollToTopIcon duration="700" />
      </div>

    </div>
  </Fragment>
)

class Intro extends React.Component {

  constructor( props ) {
    super( props );
    this.state = { 
      tmp:null,
    };
  }

  componentDidMount() {
  }

  render() {
    return ( 
      <Fragment>
        <FrontPage/>
        <BirtaCollection/>
        <FrontPageJera/>
        <AccountPlans/>
        <Projects/>
        <About/>
      </Fragment>
    )
  }
}

export default Intro
